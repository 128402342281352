
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class ConfirmDeleteNewsComponent extends Vue {
  /**
   * indicates if the button is loading
   */
  private isLoading = false;

  /**
   * closes the modal
   */
  private dismiss(deleted: boolean = false) {
    this.$emit('closeDialog', deleted);
  }

  /**
   * is called when the delete button is clicked, closes modal and tells the info feed view to delete the item
   * @private
   */
  private onDeletePressed(): void {
    this.dismiss(true);
  }
}
