
import {Component, Prop} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';

//@ts-ignore
import braintree from 'braintree-web';
import PaymentMethod from "@/models/PaymentMethod";

@Component
export default class ConfirmDeletePaymentComponent extends mixins(ErrorMessageHandlerMixin) {
  @Prop({required: true})
  private paymentMethod!: PaymentMethod;

  @Prop({required: true})
  private onDeleteConfirmed!: (method: PaymentMethod) => Promise<boolean>;

  /**
   * flag that indicates if the dialog is loading
   * @private
   */
  private isLoading = false;

  /**
   * is called when user clicks on confirm button, tries to delete the payment method
   * @private
   */
  private async onConfirmed() {
    this.isLoading = true;
    const succeeded = await this.onDeleteConfirmed(this.paymentMethod);
    this.isLoading = false;
    if(succeeded) this.dismiss(true);
  }

  /**
   * closes the modal
   */
  private dismiss(deleted: boolean = false) {
    this.$emit('closeDialog', deleted);
  }
}
